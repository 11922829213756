import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const TestimonialCarousel = () => {
  return (
    <StaticQuery
      query={graphql`
        query FeaturedTestimonialQuery {
          markdownRemark(
            frontmatter: { templateKey: { eq: "testimonials-page" } }
          ) {
            frontmatter {
              testimonials {
                quote
                author
                isFeatured
              }
            }
          }
        }
      `}
      render={(data) => (
        <section className="hero is-light">
          <div className="testimonial slide container">
            <Carousel
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              stopOnHover={true}
              transitionTime={1000}
              autoPlay
              interval={7000}
              infiniteLoop
              dynamicHeight={true}
            >
              {data.markdownRemark.frontmatter.testimonials
                .filter((testimonial) => testimonial.isFeatured)
                .map((testimonial, index) => (
                  <div key={`content-${index}`} className="hero-body">
                    <span>
                      <div className="is-size-5 is-italic">
                        {testimonial.quote}
                      </div>
                      <br />
                      <div className="is-size-5 has-font-weight-semibold has-text-primary">
                        - {testimonial.author}
                      </div>
                    </span>
                  </div>
                ))}
            </Carousel>
          </div>
        </section>
      )}
    />
  );
};

export default TestimonialCarousel;
